.App {
  border-spacing: 0px;
  text-align: center;
  background-color: #f9f4ef;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  font-size: calc(10px + 2vmin);
  color: #716040;
  overflow: hidden;
}
#svg {
  position: absolute;
  margin-top: 0px;
}

.profilePic {
  border-radius: 50%;
  height: 100%;
  width: 100%;

  margin-bottom: 10%;
}

.avatar {
  width: 25vh;
  height: 25vh;
  padding-top: 2%;
}

.heading {
  color: #001e1d;
}
.bio {
  width: 35%;
  height: 30%;
  margin: 2%;
}
.bioParagraph {
  width: 60%;
  font-size: 110%;
  padding-top: 2%;
  padding-bottom: 10%;
}
.about {
  width: 100vw;
  height: 100vh;
  padding-top: 100px;
  background-image: 'https://giphy.com/embed/UqqlBrEzKnSBdlRwLn';
  color: #0f3433;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.contact {
  height: 50vh;
  min-height: 405px;
  background-color: #abd1c6;
  color: #0f3433;
}

.projects {
  height: 100vh;
  width: 100vw;
  background-color: #004643;
  color: #abd1c6;
}
#swiper {
  display: flex;
}
.projectCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  overflow-y: scroll;
  height: 60vh;
}
.toggles {
  padding-bottom: 30px;
}
.projects h1 {
  color: #fffffe;
}

.projectContainer {
  background-color: #abd1c6;
  color: #0f3433;
  height: 400px;
  width: 100%;
  transition: 1500ms;
  transform-style: preserve-3d;
  border-radius: 15px;
  padding-bottom: 25px;
}

.projectCard {
  width: 25vw;
  position: relative;
  min-width: 160px;
  margin: 2vw 2vw;
}
.projectCard h2 {
  color: #001e1d;
}

.projectCard:hover > .projectContainer {
  cursor: pointer;
  transform: rotateY(180deg);
  cursor: pointer;
}

.front {
  position: absolute;
  width: 100%;
  transform: translateZ(1);
  height: 100%;
}
.back {
  background-color: #abd1c6;
  transform: rotateY(180deg);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-around;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 15px;
}

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  background-color: #eaddcf;
  color: #020826;
  font-size: 5vw;
  text-decoration: none;
  color: #282c34;
  font-weight: bold;
  max-height: 6vw;
}
@media screen and (min-width: 1000px) {
  .nav {
    font-size: 40px;
  }
}
@media screen and (min-width: 1300px) {
  .nav {
    font-size: 50px;
  }
}

.inputName {
  border: green 5px dashed;
}
.Navbar {
  width: 100%;
  height: 7vw;
  max-height: 60px;
  display: flex;
  justify-content: space-evenly;
  background-color: antiquewhite;
  color: #282c34;
  position: fixed;
  z-index: 2;
}
.content {
  height: 100vh;
}

.projectLink {
  width: 100%;
}

.projectButton {
  text-decoration: none;
  color: #282c34;
  border: 2px solid #001e1d;
  width: 50%;
  margin: 25%;
  font-size: larger;
}
.projectButton:hover {
  background-color: #fffffe;
  cursor: pointer;
}
.contact {
  min-height: 60vh;
}
.footer {
  background-color: antiquewhite;
  color: #282c34;
  display: flex;
  justify-content: left;
  font-size: 5vw;
  width: 100%;
  height: fit-content;
  max-height: 65px;
  min-height: 6vw;
  bottom: 0px;
}

.social {
  font-size: 30px;
  text-decoration: none;
  color: #282c34;
  margin-left: 25px;
}
